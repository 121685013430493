import { FaSquareCheck } from "react-icons/fa6";
import { FaRegWindowClose } from "react-icons/fa";

export const ShowAppropriateResult = (result) => {
  return result === "pending" ? "-" : result;
};
export const ShowAppropriateStatus = (status) => {
  return status === "pending" ? (
    "-"
  ) : status === "true" ? (
    <>
      <span>Won</span> <FaSquareCheck color="var(--secondary-clr)" size={20} />
    </>
  ) : (
    <>
      <span>Lost</span>
      <FaRegWindowClose size={20} color="red" />
    </>
  );
};
