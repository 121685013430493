import React from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const Blog = ({ blog, index }) => {
  const { title, mainImage, slug, _createdAt, spaintitle, portugaltitle } =
    blog;

  const selectedLg = localStorage.getItem("i18nextLng");

  const showAppropriateTitle =
    selectedLg == "es"
      ? spaintitle
      : selectedLg === "en"
      ? title
      : portugaltitle;

  return (
    <Link
      to={`/news-updates/${slug?.current}`}
      id={`blog_item_${index}`}
      className="top_update"
    >
      <img src={mainImage?.asset?.url} alt="" />
      <div className="text_container">
        <div className="news_indicator">
          <h3>Football</h3>
        </div>
        <p className="brief_text">{showAppropriateTitle}</p>
        <p className="date_text">
          {moment(_createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        </p>
      </div>
    </Link>
  );
};

export default Blog;
