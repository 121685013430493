import React, { useState } from "react";
import "./index.css";
import { Button, Footer, Header } from "../../components";
import {
  MdOutlineEmail,
  MdOutlineVisibilityOff,
  MdOutlineVisibility,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { Slide, toast } from "react-toastify";

const Login = () => {
  const [pVisible, setPVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [wait, setwait] = useState(false);
  const togglePasswordVisible = () => setPVisible(!pVisible);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { baseUrl } = useGlobalContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = (data) => {
    setloading(true);
    setTimeout(() => {
      setwait(true);
    }, 15000);
    axios
      .post(`${baseUrl}auth/login`, data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setwait(false);
          setloading(false);
          toast.success("User Verification Complete", { transition: Slide });
          sessionStorage.setItem(
            "accessToken",
            JSON.stringify(response?.data.accessToken)
          );
          sessionStorage.setItem("user", JSON.stringify(response?.data));
          setTimeout(() => {
            navigate("/correct-fulltime-scores");
          }, 2000);
        }
      })
      .catch((err) => {
        setloading(false);
        setwait(false);
        console.log(err);
        if (err?.response?.data === "Wrong credentials") {
          toast.error("Wrong credentials", {
            transition: Slide,
            theme: "dark",
          });
        }
      });
  };
  return (
    <>
      <Header />
      <section className="login_wrapper">
        <h2>{t("registerSect.signInVIPText")}</h2>
        <form
          className="login_form"
          onSubmit={handleSubmit((data) => handleLogin(data))}
        >
          <div>
            <label htmlFor="">{t("registerSect.emailText")}</label>
            <div className="input_wrapper">
              <MdOutlineEmail size={24} color="var(--clr-2)" />
              <input
                type="email"
                {...register("email", {
                  required: "Email is " + t("registerSect.requiredText"),
                })}
              />
            </div>
            <span>{errors?.email?.message}</span>
          </div>
          <div className="password_wrapper">
            <label htmlFor="">{t("registerSect.passwordText")}</label>
            <div className="input_wrapper">
              {!pVisible ? (
                <MdOutlineVisibilityOff
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              ) : (
                <MdOutlineVisibility
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              )}
              <input
                type={pVisible ? "text" : "password"}
                {...register("password", {
                  required: "Password is " + t("registerSect.requiredText"),
                })}
              />
            </div>
            <span>{errors?.password?.message}</span>
          </div>
          <Button
            title={
              loading
                ? "Loading..."
                : wait
                ? "Wait, Validating user..."
                : t("btnTexts.signIn")
            }
            border="none"
            backgroundColor="var(--primary-clr)"
            color="var(--clr-1)"
            height={50}
            width={450}
          />
        </form>
        <p>
          {t("registerSect.dontHaveAccountText")}{" "}
          <Link to={"/auth/register"}>{t("btnTexts.joinAsVIP")}</Link>
        </p>
        <p>
          {t("registerSect.forgetPassText")}?{" "}
          <Link to={"/auth/forget-password"}>
            {t("registerSect.resetPassText")}
          </Link>
        </p>
      </section>
      <Footer />
    </>
  );
};

export default Login;
