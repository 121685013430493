import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PredictionTipsTab = () => {
  const { t } = useTranslation();
  const selectedLg = localStorage.getItem("i18nextLng");

  const showAppropriatedClasName =
    selectedLg === "es"
      ? "es_prediction_tab_navbar"
      : selectedLg === "en"
      ? "en_prediction_tab_navbar"
      : "pt_prediction_tab_navbar";

  return (
    <div className="prediction_sect_wrapper">
      <div className={`prediction_tab_navbar ${showAppropriatedClasName}`}>
        <Link to={"/over-2.5"}>{t("predictionTabs.over2")}</Link>
        <Link to={"/btts-gg"}>{t("predictionTabs.btts/gg")}</Link>
        <Link to={"/free-accumulators"}>{t("navItems.freeAccumulators")}</Link>
        <Link to={"/handicap"}>{t("predictionTabs.handicap")}</Link>
        <Link to={"/correct-fulltime-scores"}>
          {t("predictionTabs.correctScore")}
        </Link>
      </div>
    </div>
  );
};

export default PredictionTipsTab;
