export const LANGUEGES = [
  {
    code: "es",
    name: "Spanish",
    country_code: "es",
  },
  {
    code: "pt",
    name: "Português",
    country_code: "pt",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];
