import React, { Fragment, useEffect, useState } from "react";
import "./index.css";
import { Blog, Footer, Header } from "../../components";
import english from "../../assets/English.jpg";
import banner from "../../assets/Spanish.jpg";
import portugal from "../../assets/Portuguese.jpg";
import { useGlobalContext } from "../../context/context";
import { useParams } from "react-router";
import { client } from "../../utils/client";
import moment from "moment";
import BlockContent from "@sanity/block-content-to-react";
import { useTranslation } from "react-i18next";

const UpdateNews = () => {
  const [blogPost, setBlogPost] = useState([]);
  const {} = useTranslation();
  const { slug } = useParams();
  const selectedLg = localStorage.getItem("i18nextLng");
  const { getPosts, blogs } = useGlobalContext();
  const appropriateImage =
    selectedLg === "es" ? banner : selectedLg === "en" ? english : portugal;

  const {
    title,
    mainImage,
    _createdAt,
    body,
    portugaltitle,
    spaintitle,
    spainbody,
    portugalbody,
  } = blogPost;

  // Check Title To Display
  const showAppropriateTitle =
    selectedLg === "en"
      ? title
      : selectedLg === "es"
      ? spaintitle
      : portugaltitle;

  // Check Body To Display
  const showAppropriateBody =
    selectedLg === "en" ? body : selectedLg === "es" ? spainbody : portugalbody;

  const getBlogPost = () => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
        title,spaintitle,portugaltitle,spainbody,portugalbody,
        body,
        slug,
        _createdAt,
        _updatedAt,
         mainImage {
           asset -> {
             url
           }
         }
       }`
      )
      .then((res) => {
        setBlogPost(res[0]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getPosts();
    getBlogPost();
  }, [slug]);

  return (
    <>
      <Header />
      <div className="update_news_item">
        <div className="update_news_item_hero">
          <img src={appropriateImage} alt="banner" />
          <h2>Get Up to 300$ on Bet365</h2>
        </div>
        <div className="news_indicator">
          <h3>Football</h3>
        </div>
        <h2>{showAppropriateTitle}</h2>
        <p className="date_text">
          {moment(_createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        </p>
        <img src={mainImage?.asset?.url} alt="" />
        <div className="block__content">
          <BlockContent
            dataset="production"
            projectId="ynjw5sj4"
            blocks={showAppropriateBody}
          />
        </div>

        <div className="related_news_sect_wrapper">
          <h2>More News</h2>
          <div className="related_news_sect">
            {blogs.slice(0, 4).map((blog, index) => (
              <Fragment key={index}>
                <Blog index={index} blog={blog} />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UpdateNews;
