import React, { Fragment, useEffect } from "react";
import "./index.css";
import {
  Banner,
  Blog,
  Footer,
  Header,
  PredictionTipsTab,
} from "../../components";
import { useGlobalContext } from "../../context/context";
import { useTranslation } from "react-i18next";

const News = () => {
  const { getPosts, blogs } = useGlobalContext();
  const {} = useTranslation();

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      <Header />
      <section className="news_wrapper">
        <PredictionTipsTab />
        <div className="news_updates_wrapper">
          {blogs.reverse().map((blog, index) => (
            <Fragment key={index}>
              <Blog blog={blog} index={index} />
            </Fragment>
          ))}
        </div>
        <Banner />
      </section>
      <Footer />
    </>
  );
};

export default News;
