import React, { useContext, useState } from "react";
// import "react-toastify/dist/ReactToastify.css";
import { client } from "../utils/client";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  // const baseUrl = "http://localhost:5000/api/";
  const baseUrl = "https://correct-score-api.onrender.com/api/";
  const [blogs, setBlogs] = useState([]);
  const [freeAccumulators, setFreeAccumulators] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPosts = () => {
    client
      .fetch(
        `*[_type == "post"] {
     title,spaintitle,portugaltitle,
     body,
     slug,
     _createdAt,
     _updatedAt,
      mainImage {
        asset -> {
          url
        }
      }
    }`
      )
      .then((data) => setBlogs(data))
      .catch((erro) => {});
  };

  const getFreeAccumulatorsPredictions = () => {
    setLoading(true);
    client
      .fetch(`*[_type == "freeaccumulators"]`)
      .then((data) => {
        setLoading(false);
        setFreeAccumulators(data);
      })
      .catch((err) => {});
  };

  return (
    <AppContext.Provider
      value={{
        blogs,
        getPosts,
        getFreeAccumulatorsPredictions,
        loading,
        freeAccumulators,
        baseUrl,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
