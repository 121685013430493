import React from "react";
import "./index.css";

const Button = ({
  title,
  icon,
  width,
  height,
  backgroundColor,
  color,
  border,
  handleFunc,
}) => {
  return (
    <button
      onClick={handleFunc}
      className="btn"
      style={{ width, height, backgroundColor, color, border }}
    >
      {title} {icon}
    </button>
  );
};

export default Button;
