import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import DisplayPrediction from "../DisplayPrediction";
import Loader from "../Loader";
import Button from "../Button";

import { FaFlagCheckered } from "react-icons/fa";

const DisplayPredictions = ({
  predictionsPayload,
  loading,
  transferStep,
  toggleModal,
}) => {
  const { t } = useTranslation();
  return (
    <div className="display_predictions_parent_wrapper">
      <div className="display_predictions">
        <div className="display_predictions_header">
          <div className="display_predictions_header_icon_sect">
            <FaFlagCheckered size={24} />
          </div>
          <div className="match_wrapper">
            <h3>{t("matchText")}</h3>
          </div>
          <div className="tip_wrapper">
            <h3>{t("tipText")}</h3>
          </div>
          <div className="time_wrapper">
            <h3>{t("timeText")}</h3>
          </div>
          <div className="result_wrapper">
            <h3>{t("resultText")}</h3>
          </div>
          <div className="status_wrapper">
            <h3>{t("statusText")}</h3>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {predictionsPayload.map((prediction, index) => (
              <Fragment key={index}>
                <DisplayPrediction
                  prediction={prediction}
                  transferStep={transferStep}
                  toggleModal={toggleModal}
                />
              </Fragment>
            ))}
          </>
        )}
        <div className="display_prediction_footer">
          <Button
            border="1px solid var(--clr-1)"
            backgroundColor={"transparent"}
            width={150}
            color="var(--clr-1)"
            height={40}
            title={"Previous"}
          />
          <Button
            border="1px solid var(--clr-1)"
            backgroundColor={"transparent"}
            width={150}
            color="var(--clr-1)"
            height={40}
            title={"Today"}
          />
          <Button
            border="1px solid var(--clr-1)"
            backgroundColor={"transparent"}
            width={150}
            color="var(--clr-1)"
            height={40}
            title={"Tomorrow"}
          />
        </div>
      </div>
    </div>
  );
};

export default DisplayPredictions;
