import React from "react";
import "./index.css";

import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const PopUp = ({ toggleModal, step }) => {
  const { t } = useTranslation();

  return (
    <section className="pop_up">
      <div className="modal_container">
        <div className="modal_header_sect">
          <h3>Correct Score</h3>
          <IoMdClose color="white" size={24} onClick={toggleModal} />
        </div>
        {step == 1 ? (
          <p>
            Please for you to have access to the VIP Correct Scores, reach out
            to customer care through support chatbot or telegram. Thank you!
          </p>
        ) : step == 2 ? (
          <p>{t("revealText")}</p>
        ) : step == 3 ? (
          <p>{t("maintainanceText")}</p>
        ) : (
          ""
        )}
        <a href="mailto:support@scorecorrecteurope.com">
          {t("Support@scorecorrecteurope.com")}
        </a>
      </div>
    </section>
  );
};

export default PopUp;
