import React, { useState } from "react";
import "./index.css";
import { Button, Footer, Header } from "../../components";
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useGlobalContext } from "../../context/context";
import { Slide, toast } from "react-toastify";

const ResetPassword = () => {
  const [pVisible, setPVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const togglePasswordVisible = () => setPVisible(!pVisible);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { baseUrl } = useGlobalContext();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleRegistration = (data) => {
    setloading(true);
    data.resetToken = id;

    axios
      .post(`${baseUrl}auth/reset`, data)
      .then((response) => {
        setloading(false);
        toast.success("Password Changed Successfully");
        if (response.status === 200) {
          setTimeout(() => {
            navigate("/auth/login");
          }, 2000);
        }
      })
      .catch((error) => {
        setloading(false);
        if (error?.response?.data === "Passwords do not match") {
          toast.error("Password Does not Matched", {
            transition: Slide,
            theme: "dark",
          });
        } else {
          toast.error("Token Expired", {
            transition: Slide,
            theme: "dark",
          });
        }
      });
  };
  return (
    <>
      <Header />
      <section className="login_wrapper">
        <h2>{t("forgetPasswordSect.resetPassword")}</h2>
        <form
          className="login_form"
          onSubmit={handleSubmit((data) => handleRegistration(data))}
        >
          <div className="password_wrapper">
            <label htmlFor="">{t("registerSect.passwordText")}</label>
            <div className="input_wrapper">
              {!pVisible ? (
                <MdOutlineVisibilityOff
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              ) : (
                <MdOutlineVisibility
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              )}
              <input
                type={pVisible ? "text" : "password"}
                {...register("password", {
                  required: "Password is " + t("registerSect.requiredText"),
                  minLength: 8,
                  maxLength: 20,
                })}
              />
            </div>
            <span>{errors.password?.message}</span>
          </div>
          <div className="password_wrapper">
            <label htmlFor="">{t("registerSect.confirmPasswordText")}</label>
            <div className="input_wrapper">
              {!pVisible ? (
                <MdOutlineVisibilityOff
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              ) : (
                <MdOutlineVisibility
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              )}
              <input
                type={pVisible ? "text" : "password"}
                {...register("confirmpassword", {
                  required:
                    "Confirm Password is " + t("registerSect.requiredText"),
                  minLength: 8,
                  maxLength: 20,
                })}
              />
            </div>
            <span>{errors.confirmpassword?.message}</span>
          </div>
          <Button
            title={
              loading ? "loading..." : t("forgetPasswordSect.resetPassword")
            }
            border="none"
            backgroundColor="var(--primary-clr)"
            color="var(--clr-1)"
            height={50}
            width={450}
          />
        </form>
      </section>
      <Footer />
    </>
  );
};

export default ResetPassword;
