import React from "react";
import { Button, Footer, Header } from "../../components";
import "./index.css";
import { MdOutlineEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <section className="contact_us_wrapper">
        <h1 className="contact_us_bold_text">{t("contactSect.getInTouch")}</h1>
        <p>{t("contactSect.fillFormText")}</p>
        <form className="login_form">
          <div>
            <label htmlFor="">{t("registerSect.nameText")}</label>
            <div className="input_wrapper">
              <input type="text" />
            </div>
          </div>
          <div>
            <label htmlFor="">{t("registerSect.emailText")}</label>
            <div className="input_wrapper">
              <MdOutlineEmail size={24} color="var(--clr-2)" />
              <input type="email" />
            </div>
          </div>
          <div>
            <label htmlFor="">{t("contactSect.phoneText")}</label>
            <div className="input_wrapper">
              <input type="tel" />
            </div>
          </div>
          <div className="text_area_wrapper">
            <label htmlFor="">{t("contactSect.messageText")}</label>
            <textarea placeholder={t("contactSect.messageText")}></textarea>
          </div>

          <Button
            title={t("contactSect.sendMessageText")}
            border="none"
            backgroundColor="var(--primary-clr)"
            color="var(--clr-1)"
            height={50}
            width={600}
          />
        </form>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
