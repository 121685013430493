import React from "react";
import "./index.css";
import spain from "../../assets/Spanish.jpg";
import english from "../../assets/English.jpg";
import port from "../../assets/Portuguese.jpg";

import { IoMdClose } from "react-icons/io";

const PopUp = ({ setPopup }) => {
  const selectedLg = localStorage.getItem("i18nextLng");

  const togglePopUp = () => setPopup(false);
  return (
    <section className="pop_up">
      <div className="inner">
        <IoMdClose size={25} color="white" onClick={togglePopUp} />
        <a
          onClick={togglePopUp}
          href="https://www.bet365.com/#/HO/"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={
              selectedLg === "es" ? spain : selectedLg === "en" ? english : port
            }
            alt=""
          />
        </a>
      </div>
    </section>
  );
};

export default PopUp;
