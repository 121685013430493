import React, { useEffect, useState } from "react";
import { Banner, Footer, Header, PredictionTipsTab } from "../../components";
import "./index.css";
import { client } from "../../utils/client";
import { useParams } from "react-router-dom";
import moment from "moment";

const Testimonies = () => {
  const [testimony, setTestimony] = useState([]);
  const { slug } = useParams();

  const getTestimony = () => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
            title,
            name,
            slug,
            _createdAt,brief,
             proof {
               asset -> {
                 url
               }
             }
           }`
      )
      .then((res) => {
        setTestimony(res[0]);
      })
      .catch((err) => {});
  };

  const { proof, name, title, _createdAt, brief } = testimony;

  useEffect(() => {
    getTestimony();
  }, []);
  return (
    <>
      <Header />
      <section className="testimonials_wrapper">
        <PredictionTipsTab />
        <div className="testimony_sect">
          <img src={proof?.asset?.url} alt="" />
          <h2>{title}</h2>
          <p>{name}</p>
          <p>{brief}</p>
          <p className="date_text">
            {moment(_createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </div>
        <Banner />
      </section>
      <Footer />
    </>
  );
};

export default Testimonies;
