import React, { useState } from "react";
import "./index.css";
import { Button, Footer, Header } from "../../components";
import { MdOutlineEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { Slide, toast } from "react-toastify";

const Login = () => {
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const { baseUrl } = useGlobalContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = (data) => {
    setloading(true);
    axios
      .post(`${baseUrl}auth/forget`, data)
      .then((response) => {
        if (response.status === 200) {
          setloading(false);
          toast.success("Please Check Your Email For Reset Password Link", {
            transition: Slide,
          });
        }
      })
      .catch((err) => {
        setloading(false);
        if (err?.response?.data === "Wrong credentials") {
          toast.error("Wrong credentials", {
            transition: Slide,
            theme: "dark",
          });
        }
      });
  };
  return (
    <>
      <Header />
      <section className="login_wrapper">
        <h2>{t("forgetPasswordSect.forgetPasswordText")}</h2>
        <p>{t("forgetPasswordSect.forgetParagraph")}</p>
        <form
          className="login_form"
          onSubmit={handleSubmit((data) => handleLogin(data))}
        >
          <div>
            <label htmlFor="">{t("registerSect.emailText")}</label>
            <div className="input_wrapper">
              <MdOutlineEmail size={24} color="var(--clr-2)" />
              <input
                type="email"
                {...register("email", {
                  required: "Email is " + t("registerSect.requiredText"),
                })}
              />
            </div>
            <span>{errors?.email?.message}</span>
          </div>
          <Button
            title={loading ? "Loading..." : t("forgetPasswordSect.continue")}
            border="none"
            backgroundColor="var(--primary-clr)"
            color="var(--clr-1)"
            height={50}
            width={450}
          />
        </form>
      </section>
      <Footer />
    </>
  );
};

export default Login;
