import React from "react";
import "./index.css";
import gif from "../../assets/score.gif";
import mobilegif from "../../assets/mobile-gif.gif";
import { IoMdClose } from "react-icons/io";

const Gif = ({ setGifPopUp }) => {
  const toggleGifPopUp = () => setGifPopUp(false);
  return (
    <div className="gif_wrapper">
      <IoMdClose size={25} color="white" onClick={toggleGifPopUp} />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://t.me/+B7e70kUvJjkwMTU0"
        onClick={toggleGifPopUp}
      >
        <img className="desktop_gif" src={gif} alt="" />
        <img className="mobile_gif" src={mobilegif} alt="" />
      </a>
    </div>
  );
};

export default Gif;
