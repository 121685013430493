import React from "react";
import "./index.css";
import { Footer, Header } from "../../components";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <section className="about_us_wrapper">
        <h1 className="about_title">
          {t("aboutSect.aboutScoreText")} <span>SCORESORRECTEUROPE</span>
        </h1>
        <p className="about_info_text">{t("aboutSect.aboutIntro")}</p>
        <h3 className="mini_title">{t("aboutSect.aboutPurposeText")}</h3>
        <p className="about_info_text">
          {t("aboutSect.aboutPurposeIntroText")}
        </p>
        <h3 className="mini_title">{t("aboutSect.aboutMissionText")}</h3>
        <p className="about_info_text">
          {t("aboutSect.aboutMissionIntroText")}
        </p>
        <h1 className="about_title terms">
          {t("aboutSect.aboutTermsText").slice(0, 9)}{" "}
          <span>{t("aboutSect.aboutTermsText").slice(9)}</span>
        </h1>
        <h3 className="mini_title">{t("aboutSect.termsBoldText1")}:</h3>
        <p className="about_info_text">{t("aboutSect.termsBoldText1Intro")}</p>
        <h3 className="mini_title">{t("aboutSect.termsBoldText2")}:</h3>
        <p className="about_info_text">{t("aboutSect.termsBoldText2Intro")}</p>
        <h3 className="mini_title">{t("aboutSect.termsBoldText3")}:</h3>
        <p className="about_info_text">{t("aboutSect.termsBoldText3Intro")}</p>
        <h3 className="mini_title">{t("aboutSect.termsBoldText4")}: </h3>
        <p className="about_info_text">{t("aboutSect.termsBoldText4Intro")}</p>
        <h3 className="mini_title">{t("aboutSect.termsBoldText5")}: </h3>
        <p className="about_info_text">{t("aboutSect.termsBoldText5Intro")}</p>
        <h1 className="about_title terms">
          {t("aboutSect.aboutPrivacyText").slice(0, 13)}
          <span>{t("aboutSect.aboutPrivacyText").slice(13)}</span>
        </h1>
        <h3 className="mini_title">{t("aboutSect.aboutPrivacyBoldText1")}: </h3>
        <p className="about_info_text">
          {t("aboutSect.aboutPrivacyBoldItroText1")}
        </p>
        <h3 className="mini_title">{t("aboutSect.aboutPrivacyBoldText2")}: </h3>
        <p className="about_info_text">
          {t("aboutSect.aboutPrivacyBoldItroText2")}
        </p>
        <h3 className="mini_title">{t("aboutSect.aboutPrivacyBoldText3")}: </h3>
        <p className="about_info_text">
          {t("aboutSect.aboutPrivacyBoldItroText3")}
        </p>
        <h3 className="mini_title">{t("aboutSect.aboutPrivacyBoldText4")}: </h3>
        <p className="about_info_text">
          {t("aboutSect.aboutPrivacyBoldItroText4")}
        </p>
        <h3 className="mini_title">{t("aboutSect.aboutPrivacyBoldText5")}: </h3>
        <p className="about_info_text">
          {t("aboutSect.aboutPrivacyBoldItroText5")}
        </p>
      </section>
      <Footer />
    </>
  );
};

export default About;
