import { Route, Routes, useLocation } from "react-router-dom";
import {
  About,
  BothTeamToScore,
  ContactUs,
  ForgetPassword,
  FreeAccumulators,
  FullTimeCorrectScore,
  Gif,
  Handicap,
  Home,
  LeagueStandings,
  Login,
  News,
  Over2,
  PopUp,
  Register,
  ResetPassword,
  Testimonies,
  UpdateNews,
} from "./pages";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from "./utils/PrivateRoutes";
import { client } from "./utils/client";

function App() {
  const { pathname } = useLocation();
  const [popup, setPopup] = useState(false);
  const [gifPopup, setGifPopup] = useState(false);
  const [testimony, setTestimony] = useState([]);
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getProofs = () => {
    client
      .fetch(
        `*[_type == "proof"] {
          title,
            name,
            slug,
            _createdAt,
            spaintitle,
            portugaltitle,
            
        }`
      )
      .then((data) => {
        setTestimony(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setTimeout(() => {
      setPopup(true);
      setGifPopup(true);
    }, 1000);
    getProofs();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let prooflength = testimony.length - 1;
      if (counter === prooflength) {
        setCounter(0);
      } else {
        setCounter(counter + 1);
      }
    }, 40000);
    toast.success(
      `${
        testimony.length === 0
          ? "Score Correct Europe"
          : testimony[counter]?.name
      }: ${
        testimony.length === 0
          ? "Your Way To Riches"
          : testimony[counter]?.title
      }`,
      {
        theme: "dark",
        transition: Slide,
      }
    );
  }, [counter]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/league-standing" element={<LeagueStandings />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/auth/forget-password" element={<ForgetPassword />} />
        <Route path="/auth/reset-password/:id" element={<ResetPassword />} />
        <Route path="/news-updates" element={<News />} />
        <Route path="/news-updates/:slug" element={<UpdateNews />} />
        <Route path="/over-2.5" element={<Over2 />} />
        <Route path="/btts-gg" element={<BothTeamToScore />} />
        <Route path="/free-accumulators" element={<FreeAccumulators />} />
        <Route path="/handicap" element={<Handicap />} />
        <Route path="/testimonials/:slug" element={<Testimonies />} />
        <Route element={<PrivateRoutes />}>
          <Route
            path="/correct-fulltime-scores"
            element={<FullTimeCorrectScore />}
          />
        </Route>
      </Routes>
      {popup && <PopUp setPopup={setPopup} />}
      {gifPopup && <Gif setGifPopUp={setGifPopup} />}
      <ToastContainer />
    </>
  );
}

export default App;
