import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CiInstagram, CiTwitter } from "react-icons/ci";
import { FaTelegram, FaWhatsapp } from "react-icons/fa";
import logo from "../../assets/logo.svg";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <img src={logo} alt="footer logo" />
      <nav className="nav">
        <Link to={"/about-us"}>{t("aboutUs")}</Link>
        <Link to={"/correct-fulltime-scores"}>
          {t("predictionTabs.correctScore")}
        </Link>
        <Link to={"/news-updates"}>{t("navItems.sportNews")}</Link>
        <Link to={"/league-standing"}>{t("navItems.leagueStandings")}</Link>
        <Link to={"/contact-us"}>{t("contactUs")}</Link>
      </nav>
      <div className="footer_icons">
        <Link>
          <CiInstagram />
        </Link>
        <Link>
          <CiTwitter />
        </Link>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://t.me/+B7e70kUvJjkwMTU0"
        >
          <FaTelegram />
        </a>
        <Link>
          <FaWhatsapp />
        </Link>
      </div>
      <p className="trusted_by_text">{t("trustText")}</p>
    </footer>
  );
};

export default Footer;
