import React, { useState } from "react";
import "./index.css";
import { Button, Footer, Header } from "../../components";
import {
  MdOutlineEmail,
  MdOutlineVisibilityOff,
  MdOutlineVisibility,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useGlobalContext } from "../../context/context";
import { Slide, toast } from "react-toastify";

const Register = () => {
  const [pVisible, setPVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const togglePasswordVisible = () => setPVisible(!pVisible);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { baseUrl } = useGlobalContext();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleRegistration = (data) => {
    setloading(true);
    axios
      .post(`${baseUrl}auth/register`, data)
      .then((response) => {
        setloading(false);
        toast.success("Account Created");
        if (response.status === 201) {
          setTimeout(() => {
            navigate("/auth/login");
          }, 2000);
        }
      })
      .catch((error) => {
        setloading(false);
        if (error?.response?.data?.keyValue?.email === data?.email) {
          toast.error("User Already Exist", {
            transition: Slide,
            theme: "dark",
          });
        }
      });
  };
  return (
    <>
      <Header />
      <section className="login_wrapper">
        <h2>{t("registerSect.registerVIPText")}</h2>
        <form
          className="login_form"
          onSubmit={handleSubmit((data) => handleRegistration(data))}
        >
          <div>
            <label htmlFor="">{t("registerSect.nameText")}</label>
            <div className="input_wrapper">
              <input
                type="text"
                {...register("userName", {
                  required: "Username is " + t("registerSect.requiredText"),
                })}
              />
            </div>
            <span>{errors.userName?.message}</span>
          </div>

          <div>
            <label htmlFor="">{t("registerSect.emailText")}</label>
            <div className="input_wrapper">
              <MdOutlineEmail size={24} color="var(--clr-2)" />
              <input
                type="email"
                {...register("email", {
                  required: "Email is " + t("registerSect.requiredText"),
                })}
              />
            </div>
            <span>{errors.email?.message}</span>
          </div>
          <div className="password_wrapper">
            <label htmlFor="">{t("registerSect.passwordText")}</label>
            <div className="input_wrapper">
              {!pVisible ? (
                <MdOutlineVisibilityOff
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              ) : (
                <MdOutlineVisibility
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              )}
              <input
                type={pVisible ? "text" : "password"}
                {...register("password", {
                  required: "Password is " + t("registerSect.requiredText"),
                  minLength: 8,
                  maxLength: 20,
                })}
              />
            </div>
            <span>{errors.password?.message}</span>
          </div>
          <div className="password_wrapper">
            <label htmlFor="">{t("registerSect.confirmPasswordText")}</label>
            <div className="input_wrapper">
              {!pVisible ? (
                <MdOutlineVisibilityOff
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              ) : (
                <MdOutlineVisibility
                  onClick={togglePasswordVisible}
                  size={24}
                  color="var(--clr-2)"
                />
              )}
              <input
                type={pVisible ? "text" : "password"}
                {...register("confirmpassword", {
                  required:
                    "Confirm Password is " + t("registerSect.requiredText"),
                  minLength: 8,
                  maxLength: 20,
                })}
              />
            </div>
            <span>{errors.confirmpassword?.message}</span>
          </div>
          <Button
            title={loading ? "loading..." : t("btnTexts.joinAsVIP")}
            border="none"
            backgroundColor="var(--primary-clr)"
            color="var(--clr-1)"
            height={50}
            width={450}
          />
        </form>
        <p>
          {t("registerSect.alreadyHaveAccountText")}?{" "}
          <Link to={"/auth/login"}>{t("btnTexts.signIn")}</Link>
        </p>
      </section>
      <Footer />
    </>
  );
};

export default Register;
