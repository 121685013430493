import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import Button from "../Button";
import { LANGUEGES } from "./constants";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { CiMenuFries, CiGlobe } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import logo from "../../assets/logo.svg";

export const Header = () => {
  const [dropdown, setDropdown] = useState(false);
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();
  const selectedLg = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));

  const toggleDropdown = (code) => {
    setDropdown(!dropdown);
    i18next.changeLanguage(code);
  };

  const toggleMenu = () => setMenu(!menu);

  const navigateToLogin = () => navigate("/auth/login");
  const navigateToSignUp = () => navigate("/auth/register");

  const clearSession = () => {
    if (accessToken) {
      sessionStorage.removeItem("accessToken");
      navigateToLogin();
    } else {
      navigateToLogin();
    }
  };

  const selectedLanguege =
    selectedLg === "es"
      ? "Spanish"
      : selectedLg === "pt"
      ? "Português"
      : "English";

  return (
    <>
      <header className="header">
        <div className="header_logo_wrapper">
          <img src={logo} alt="logo" />
        </div>
        <nav className="nav">
          <Link to={"/"}>{t("navItems.home")}</Link>

          {selectedLg === "en" ? (
            <>
              <Link to={"/correct-fulltime-scores"}>
                {t("predictionTabs.correctScore")}
              </Link>
            </>
          ) : (
            ""
          )}

          <Link to={"/over-2.5"}>{t("predictionTabs.over2")}</Link>
          <Link to={"/news-updates"}>{t("navItems.sportNews")}</Link>
          <Link to={"/league-standing"}>{t("navItems.leagueStandings")}</Link>
        </nav>
        <div className="header_last_item">
          <div className="btns_wrapper">
            <Button
              title={accessToken ? t("signOutText") : t("btnTexts.signIn")}
              border="none"
              backgroundColor="var(--primary-clr)"
              color="var(--clr-1)"
              height={40}
              width={selectedLg === "es" ? 150 : 100}
              handleFunc={clearSession}
            />

            {!accessToken && (
              <Button
                title={t("btnTexts.joinAsVIP")}
                border="none"
                backgroundColor="var(--secondary-clr)"
                color="white"
                height={40}
                handleFunc={navigateToSignUp}
              />
            )}
          </div>
          <div
            onClick={() => setDropdown(!dropdown)}
            className="languege_indicator"
          >
            <span
              className={`fi fi-${selectedLg === "en" ? "gb" : selectedLg}`}
            ></span>
            <p>{selectedLanguege}</p>
          </div>
        </div>
      </header>
      <header className="mobile_header">
        <CiMenuFries onClick={toggleMenu} color="white" size={20} />
        <div className="mobile_right_container">
          <Link onClick={clearSession} to={"/auth/login"}>
            {accessToken ? t("signOutText") : t("btnTexts.signIn")}
          </Link>
          {!accessToken && (
            <Link to={"/auth/register"}>{t("btnTexts.joinAsVIP")}</Link>
          )}
        </div>
        <CiGlobe
          color="white"
          size={20}
          onClick={() => setDropdown(!dropdown)}
        />
      </header>
      <div className={`${dropdown ? "dropdown" : "hide"}`}>
        {LANGUEGES.map(({ name, code, country_code }) => (
          <div
            onClick={() => toggleDropdown(code)}
            className={`l_tab fa-${country_code}`}
            key={code}
          >
            <span className={`fi fi-${country_code}`}></span>
            <p>{name}</p>
          </div>
        ))}
      </div>
      <nav className={`nav ${menu ? "mobile_nav" : "close_menu"}`}>
        <IoMdClose onClick={toggleMenu} color="white" size={20} />
        <Link to={"/"}>{t("navItems.home")}</Link>
        <Link to={"/free-accumulators"}>{t("navItems.freeAccumulators")}</Link>
        <Link to={"/correct-fulltime-scores"}>
          {t("predictionTabs.correctScore")}
        </Link>
        <Link to={"/news-updates"}>{t("navItems.sportNews")}</Link>
        <Link to={"/league-standing"}>{t("navItems.leagueStandings")}</Link>
      </nav>
    </>
  );
};
