import React, { useState, useEffect } from "react";
import "./index.css";
import {
  Banner,
  DisplayPredictions,
  Footer,
  Header,
  PredictionTipsTab,
} from "../../components";

import { client } from "../../utils/client";
import { useTranslation } from "react-i18next";

const Over2 = () => {
  const [over2, setOVer2] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const getOVer2Predictions = () => {
    setLoading(true);
    client
      .fetch(`*[_type == "overtwogoals"]`)
      .then((data) => {
        setLoading(false);
        setOVer2(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getOVer2Predictions();
  }, []);

  return (
    <>
      <Header />
      <section className="over2_wrapper">
        <PredictionTipsTab />
        <h1 className="over_2_title">
          {t("predictionTabs.over2")} {t("predictionText")}
        </h1>
        <DisplayPredictions predictionsPayload={over2} loading={loading} />
        <Banner />
      </section>
      <Footer />
    </>
  );
};

export default Over2;
