import React from "react";
import {
  Header,
  Footer,
  PredictionTipsTab,
  Banner,
  DisplayPredictions,
} from "../../components";
import "./index.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import { client } from "../../utils/client";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { Modal } from "..";

const FullTimeCorrectScore = () => {
  const [correctscores, setCorrectScores] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(false);
  const [modal, setModal] = useState(false);

  const { baseUrl } = useGlobalContext();
  const { userName, _id, transferStep } = JSON.parse(
    sessionStorage.getItem("user")
  );

  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const { t } = useTranslation();

  const getCorrectScores = () => {
    setloading(true);
    client
      .fetch(`*[_type == "correctscores"]`)
      .then((data) => {
        setloading(false);
        setCorrectScores(data);
      })
      .catch((err) => {});
  };

  const getUserDetails = () => {
    axios
      .get(`${baseUrl}users/${_id}`, {
        headers: { token: accessToken },
      })
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem("user", JSON.stringify(data?.data));
          setUserData(data.data);
        }
      })
      .catch((error) => {});
  };

  const toggleModal = () => {
    setModal(!modal);
    getUserDetails(accessToken);
  };

  useEffect(() => {
    getCorrectScores();
    getUserDetails();
  }, []);
  return (
    <>
      <Header />
      <section className="fulltime_correct_score_Wrapper">
        <div className="welcome_wrapper">
          <h1>
            {t("VIPWelcomeText")} {userName}, {t("joinVIPWelcomeText")}
          </h1>
          <p>{t("wedesc")}</p>
        </div>
        <PredictionTipsTab />
        <h1 className="over_2_title">VIP {t("predictionTabs.correctScore")}</h1>
        <DisplayPredictions
          predictionsPayload={correctscores}
          loading={loading}
          transferStep={transferStep}
          toggleModal={toggleModal}
        />
        <Banner />
      </section>
      {modal && <Modal toggleModal={toggleModal} step={transferStep} />}
      <Footer />
    </>
  );
};

export default FullTimeCorrectScore;
