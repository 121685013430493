import React from "react";
import {
  ShowAppropriateResult,
  ShowAppropriateStatus,
} from "../../pages/Over2/helper-method";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DisplayPrediction = ({
  prediction: { country, home, away, tip, starting, result, status },
  transferStep,
  toggleModal,
}) => {
  const { t } = useTranslation();
  return (
    <div className="display_predictions_body">
      <div className="display_predictions_header_icon_sect">
        <p>{country}</p>
      </div>
      <div className="match_wrapper">
        <p>
          {home} vs {away}
        </p>
      </div>
      <div className="tip_wrapper">
        {transferStep === 1 || transferStep === 2 || transferStep === 3 ? (
          <Link onClick={toggleModal}>{t("viewTipText")}</Link>
        ) : (
          <p>{tip}</p>
        )}
      </div>
      <div className="time_wrapper">
        <p>{starting}</p>
      </div>
      <div className="result_wrapper">
        <p> {ShowAppropriateResult(result)}</p>
      </div>
      <div className="status_wrapper">
        <p> {ShowAppropriateStatus(status)}</p>
      </div>
    </div>
  );
};

export default DisplayPrediction;
