import React, { Fragment, useEffect, useState } from "react";
import "./index.css";
import Blog from "./components/Blog";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { client } from "../../utils/client";
import { useGlobalContext } from "../../context/context";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const [topPost, setTopPost] = useState([]);
  const navigate = useNavigate();
  const selectedLg = localStorage.getItem("i18nextLng");
  const { blogs, getPosts } = useGlobalContext();
  const { t } = useTranslation();

  const navigateToNewsUpdate = () => {
    navigate("/news-updates");
  };

  // Get Top Post
  const getTopPost = () => {
    client
      .fetch(
        `*[_type == "post"] {
     title,portugaltitle,spaintitle,
     body,
     slug,
     _createdAt,
     _updatedAt,
      mainImage {
        asset -> {
          url
        }
      }
    }`
      )
      .then((data) => setTopPost(data[data.length - 1]))
      .catch((err) => {});
  };

  useEffect(() => {
    getTopPost();
    getPosts();
  }, []);
  return (
    <section className="blog_wrapper">
      <div className="top_update_wrapper">
        <h2>{t("topStory")}</h2>
        <Blog blog={topPost} />
      </div>
      <div className="news_updates">
        <div className="new_updates_top_wrapper">
          <h2>{t("navItems.sportNews")}</h2>
          <Button
            title={t("readMore")}
            border="none"
            backgroundColor="var(--primary-clr)"
            color="var(--clr-1)"
            height={50}
            width={selectedLg === "pt" ? 250 : 150}
            handleFunc={navigateToNewsUpdate}
          />
        </div>
        <div className="new_updates_wrapper">
          {blogs.slice(0, 4).map((blog, index) => (
            <Fragment key={index}>
              <Blog blog={blog} />
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
