import React from "react";
import "./index.css";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { FaTelegramPlane } from "react-icons/fa";
import banner from "../../assets/Spanish.jpg";
import english from "../../assets/English.jpg";
import portugal from "../../assets/Portuguese.jpg";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { client } from "../../utils/client";

const Banner = () => {
  const [proofs, setProofs] = useState([]);
  const { t } = useTranslation();
  const selectedLg = localStorage.getItem("i18nextLng");

  const appropriateImage =
    selectedLg === "es" ? banner : selectedLg === "en" ? english : portugal;

  const getProofs = () => {
    client
      .fetch(
        `*[_type == "proof"] {
          title,
            name,
            slug,
            _createdAt,
            spaintitle,
            portugaltitle,
            
        }`
      )
      .then((data) => {
        setProofs(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getProofs();
  }, []);

  return (
    <section className="banner_wrapper">
      <div className="banner_container">
        <img src={appropriateImage} alt="" />
        <h2>{t("depositText")}</h2>
      </div>
      <div className="telegram_sect_wrapper">
        <div className="telegram_sect">
          <h2>{t("joinOurTelegramCom")}</h2>
          <p>{t("correctScorePredictionTips")}</p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://t.me/+B7e70kUvJjkwMTU0"
          >
            <Button
              icon={<FaTelegramPlane size={24} />}
              title={t("joinTele")}
              border="none"
              backgroundColor="var(--primary-clr)"
              color="var(--clr-1)"
              height={50}
              width={300}
            />
          </a>
        </div>
        <h1 className="testi_text">Testimonials</h1>
        <div className="testimony_wrapper">
          {proofs
            .slice(0, 6)
            .reverse()
            .map(({ title, name, _createdAt, slug }, index) => (
              <div key={index} className="proof_wrapper">
                <p className="brief_text">{title}</p>
                <p className="date_text">
                  {moment(_createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                </p>
                <p className="brief_text">{name}</p>
                <Link to={`/testimonials/${slug?.current}`} className="btn">
                  see proof
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Banner;
