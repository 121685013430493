import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loader = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((_, index) => (
        <SkeletonTheme baseColor="#202020" highlightColor="#444" key={index}>
          <div className="display_predictions_body">
            <div className="display_predictions_header_icon_sect">
              <Skeleton width={25} height={20} />
            </div>
            <div className="match_wrapper">
              <Skeleton width={300} height={20} />
            </div>
            <div className="tip_wrapper">
              <Skeleton width={50} height={20} />
            </div>
            <div className="time_wrapper">
              <Skeleton width={50} height={20} />
            </div>
            <div className="time_wrapper">
              <Skeleton width={50} height={20} />
            </div>
            <div className="time_wrapper">
              <Skeleton width={50} height={20} />
            </div>
          </div>
        </SkeletonTheme>
      ))}
    </>
  );
};

export default Loader;
