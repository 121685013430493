import React, { useEffect } from "react";

import {
  Banner,
  DisplayPredictions,
  Footer,
  Header,
  PredictionTipsTab,
} from "../../components";
import { useGlobalContext } from "../../context/context";
import { useTranslation } from "react-i18next";

const FreeAccumulators = () => {
  const { t } = useTranslation();
  const { getFreeAccumulatorsPredictions, loading, freeAccumulators } =
    useGlobalContext();

  useEffect(() => {
    getFreeAccumulatorsPredictions();
  }, []);

  return (
    <>
      <Header />
      <section className="over2_wrapper">
        <PredictionTipsTab />
        <h1 className="over_2_title">{t("navItems.freeAccumulators")}</h1>
        <DisplayPredictions
          predictionsPayload={freeAccumulators}
          loading={loading}
        />
        <Banner />
      </section>
      <Footer />
    </>
  );
};

export default FreeAccumulators;
