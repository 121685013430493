import React from "react";
import "./index.css";
import { Banner, Footer, Header } from "../../components";

const LeagueStandings = () => {
  return (
    <>
      <Header />
      <div className="league_standing_wrapper">
        <div className="table_wrapper">
          <div
            id="scoreaxis-widget-97c49"
            style={{
              border: "1px var(--clr-2) solid",
              padding: 15,
              background: "var(--clr-1)",
              width: "100%",
            }}
          >
            <iframe
              id="Iframe"
              src="https://www.scoreaxis.com/widget/standings-widget/8?autoHeight=1&amp;inst=97c49"
              style={{
                width: "100%",
                border: "none",
                transition: "all 300ms ease",
              }}
            ></iframe>
          </div>
        </div>
        <Banner />
      </div>
      <Footer />
    </>
  );
};

export default LeagueStandings;
