import React from "react";

import {
  Banner,
  DisplayPredictions,
  Footer,
  Header,
  PredictionTipsTab,
} from "../../components";
import { useState } from "react";
import { client } from "../../utils/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const BothTeamToScore = () => {
  const [btts, setBtts] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const getBttsPredictions = () => {
    setLoading(true);
    client
      .fetch(`*[_type == "btts"]`)
      .then((data) => {
        setLoading(false);
        setBtts(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBttsPredictions();
  }, []);

  return (
    <>
      <Header />
      <section className="over2_wrapper">
        <PredictionTipsTab />
        <h1 className="over_2_title">
          {t("bttsText")} {t("predictionText")}
        </h1>
        <DisplayPredictions predictionsPayload={btts} loading={loading} />
        <Banner />
      </section>
      <Footer />
    </>
  );
};

export default BothTeamToScore;
