import React, { Fragment, useEffect } from "react";
import {
  Banner,
  Blogs,
  Button,
  DisplayPredictions,
  Footer,
  Header,
  PredictionTipsTab,
} from "../../components";
import "./index.css";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context/context";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedLg = localStorage.getItem("i18nextLng");
  const { getFreeAccumulatorsPredictions, loading, freeAccumulators } =
    useGlobalContext();

  const boldTextWidth = {
    width: selectedLg === "es" || selectedLg === "pt" ? "55%" : "",
  };

  useEffect(() => {
    getFreeAccumulatorsPredictions();
  }, []);

  return (
    <Fragment>
      <Header />
      <section className="home_page_wrapper">
        <PredictionTipsTab />
        <div className="home_intro_wrapper">
          <h1 style={boldTextWidth}>{t("welcomText")}</h1>
          <p>{t("homeHeroIntro")}</p>
          <div className="home_intro_join_btns_wrapper">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/+B7e70kUvJjkwMTU0"
            >
              <Button
                title={t("joinTele")}
                border="none"
                backgroundColor="var(--primary-clr)"
                color="var(--clr-1)"
                height={50}
                width={selectedLg === "es" ? 200 : 250}
              />
            </a>
            <Button
              title={t("btnTexts.joinAsVIP")}
              border="1px solid var(--clr-2)"
              backgroundColor="var(--clr1)"
              color="white"
              height={50}
              width={selectedLg === "es" ? 200 : 250}
              handleFunc={() => navigate("/auth/register")}
            />
          </div>
          <p className="trusted_by_text">{t("trustText")}</p>
        </div>
        <h1 className="over_2_title">{t("navItems.freeAccumulators")}</h1>
        <DisplayPredictions
          loading={loading}
          predictionsPayload={freeAccumulators}
        />
        <Blogs />
        <Banner />
      </section>
      <Footer />
    </Fragment>
  );
};
