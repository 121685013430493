import React from "react";

import {
  Banner,
  DisplayPredictions,
  Footer,
  Header,
  PredictionTipsTab,
} from "../../components";
import { useState } from "react";
import { client } from "../../utils/client";
import { useEffect } from "react";

const Handicap = () => {
  const [handicaps, sethandicaps] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHandicapPredictions = () => {
    setLoading(true);
    client
      .fetch(`*[_type == "handicap"]`)
      .then((data) => {
        setLoading(false);
        sethandicaps(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getHandicapPredictions();
  }, []);

  return (
    <>
      <Header />
      <section className="over2_wrapper">
        <PredictionTipsTab />
        <h1 className="over_2_title">Free Handicap Predictions</h1>
        <DisplayPredictions predictionsPayload={handicaps} loading={loading} />
        <Banner />
      </section>
      <Footer />
    </>
  );
};

export default Handicap;
